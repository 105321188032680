<template>
  <div class="relative sticky box-content h-[4rem] pt-safe top-0 z-40 bg-white">
    <div class="py-2 w-full flex-shrink-0 flex justify-center">
      <MenuMobileWraper />
      <div
        class="flex items-center justify-between w-full px-3 sm:px-8 max-w-screen-2xl"
      >
        <div class="flex items-center">
          <!-- <MenuBack v-if="allowBack" class="xl:hidden" /> -->
          <MenuBurger class="xl:hidden" />

          <div class="flex items-center xl:border-r-2 border-gray-200 xl:pr-4">
            <BaseLink theme="primary" :to="{ path: '/' }">
              <BaseIcon
                name="logo"
                size="none"
                title="Communication Platform"
                class="h-12 w-24 lg:w-32"
              />
            </BaseLink>
          </div>
          <MenuDesktop class="hidden xl:block" />
        </div>
        <div class="flex flex-shrink-0 items-center gap-1">
          <div class="flex items-center gap-2">
            <!-- <BaseLink to="/pro">
              <BaseButton
                size="xs"
                rounded="full"
                look="none"
                class="uppercase text-pro border border-pro hover:bg-pro hover:bg-opacity-10 active:border-pro focus:ring-pro focus:ring-2"
              >
                {{ $t('subscriptions.pro') }}
              </BaseButton>
            </BaseLink> -->

            <!-- <template v-if="i18n.availableLocales.length > 1">
              <BaseMenu>
                <BaseButton
                  size="xs"
                  rounded="full"
                  look="border"
                  class="uppercase border-2"
                >
                  {{ $t(`languages.${i18n.locale.value}.abbr`) }}
                </BaseButton>

                <template #menu>
                  <div class="rounded-md w-16 bg-white shadow-md mt-2.5">
                    <template
                      v-for="(item, idx) in i18n.availableLocales"
                      :key="idx"
                    >
                      <button
                        v-if="item !== i18n.locale.value"
                        class="px-4 py-2 appearance-none block w-full text-left"
                        @click="setLanguage(item)"
                      >
                        {{ $t(`languages.${item}.abbr`) }}
                      </button>
                    </template>
                  </div>
                </template>
              </BaseMenu>
            </template> -->

            <BaseLocaleLink
              v-if="authStore.auth"
              to="/users/me/favorites"
              class="flex"
            >
              <BaseButton
                size="xs"
                rounded="full"
                look="none"
                class="text-primary hover:opacity-80 !px-1 transition"
              >
                <BaseIcon size="sm" name="outline_bookmark" />
              </BaseButton>
            </BaseLocaleLink>
          </div>
          <div v-if="!authStore.auth" class="pl-5">
            <BaseButton
              theme="primary"
              look="border"
              @click="authStore.openLoginModal"
            >
              {{ $t('header.login') }}
            </BaseButton>
          </div>
          <template v-else>
            <HeaderProfileMenu />
          </template>
        </div>
      </div>
    </div>
  </div>
  <AuthLogin
    :is-open="authStore.isLoginModalOpen"
    @close="authStore.closeLoginModal"
  />
</template>

<script setup lang="ts">
// import { useI18n } from 'vue-i18n'
import { useAuth } from '~/stores/auth'

// const i18n = useI18n()
const authStore = useAuth()

// const allowBack = computed(() => {
//   const history = useRouter().options.history.state
//   const route = useRouter().currentRoute.value
//   const isValidPrevRoute = history.back && !history?.replaced

//   return isValidPrevRoute && route.meta.allowBack
// })

// const setLanguage = (langCode: string) => {
//   useSetLanguage(langCode)
// }
</script>
