<template>
  <BaseLocaleLink
    v-if="link.route"
    v-show="link.route"
    v-slot="{ isActive }"
    :to="link.route"
  >
    <div
      class="flex items-center text-gray rounded-xl py-2 px-4 space-x-4 hover:bg-ocean transition duration-150 ease-in-out"
      :class="[
        (isActive || isActiveRoute) &&
          !isEventInfoRouteSelected(link) &&
          'bg-ocean',
      ]"
    >
      <BaseIcon v-if="link.icon" size="sm" :name="link.icon" />
      <div class="pb-[2px]">
        {{ $t(link.local) }}
      </div>
    </div>
  </BaseLocaleLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { MenuItem } from '~/models/menu'
import { useMenu } from '~/stores/menu'
const { isEventInfoRouteSelected } = useMenu()

const props = defineProps({
  link: {
    type: Object as PropType<MenuItem>,
    required: true,
  },
})

const route = useRoute()
const isActiveRoute = computed(() =>
  route.path.includes(
    typeof props.link.route === 'string'
      ? props.link.route
      : props.link.route.name
  )
)
</script>
