<template>
  <button
    class="flex items-center justify-center h-10 w-10 text-gray-500 rounded-xl hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
    @click="toggleMenu"
  >
    <svg
      class="h-6 w-6"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M4 6h16M4 12h16m-16 6h16" />
    </svg>
  </button>
</template>

<script setup lang="ts">
import { useMenu } from '~/stores/menu'

const { toggleMenu } = useMenu()
</script>
