<template>
  <BaseLocaleLink
    v-if="link.route"
    v-show="link.route"
    v-slot="{ isActive }"
    :to="link.route"
  >
    <div
      class="rounded-xl py-2 px-4 hover:bg-primary hover:text-white transition duration-150 ease-in-out"
      :class="[
        (isActive || isActiveRoute) && !isEventInfoRouteSelected(link)
          ? 'bg-primary text-white'
          : 'text-gray',
      ]"
    >
      {{ $t(link.local) }}
    </div>
  </BaseLocaleLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { MenuItem } from '~/models/menu'
import { useMenu } from '~/stores/menu'
const { isEventInfoRouteSelected } = useMenu()

const props = defineProps({
  link: {
    type: Object as PropType<MenuItem>,
    required: true,
  },
})

const route = useRoute()
const isActiveRoute = computed(() =>
  route.path.includes(
    typeof props.link.route === 'string'
      ? props.link.route
      : props.link.route.name
  )
)
</script>
