import type { MenuItem } from '~/models/menu'

interface MenuState {
  menu: MenuItem[]
  isMobileMenuVisible: boolean
}

export const useMenu = defineStore('menu', {
  state: (): MenuState => {
    return {
      menu: [
        {
          id: 'feed',
          local: 'menu.feed',
          route: { name: 'index' },
          icon: 'outline_home',
          type: 'page',
        },
        {
          id: 'events',
          local: 'menu.events',
          route: '/event-info',
          icon: 'outline_ticket',
          type: 'page',
        },
        // {
        //   id: 'news',
        //   local: 'menu.news',
        //   route: { name: 'news' },
        //   icon: 'outline_home',
        //   type: 'page',
        // },
        // {
        //   id: 'video',
        //   local: 'menu.video',
        //   route: { name: 'video' },
        //   icon: 'outline_play',
        //   type: 'page',
        // },
        {
          id: 'questions',
          local: 'menu.questions',
          route: { name: 'questions' },
          icon: 'outline_question_mark_circle',
          type: 'page',
        },
        // {
        //   id: 'gamefinder',
        //   local: 'menu.gamefinder',
        //   route: {
        //     name: 'event-info-event',
        //     params: { event: 'gamefinder' },
        //   },
        //   icon: 'outline_gamepad',
        //   type: 'page',
        // },
        {
          id: 'academy',
          local: 'menu.academy',
          route: '/academy',
          icon: 'outline_book_open',
          type: 'page',
        },
      ],
      isMobileMenuVisible: false,
    }
  },

  actions: {
    hideMenu() {
      this.isMobileMenuVisible = false
    },
    showMenu() {
      this.isMobileMenuVisible = true
    },
    toggleMenu() {
      if (this.isMobileMenuVisible) {
        this.hideMenu()
      } else {
        this.showMenu()
      }
    },
    // we want to make events menu item not active when selected event-info link from the menu
    isEventInfoRouteSelected(link: MenuItem) {
      const route = useRoute()
      return this.$state.menu.find(
        (item) =>
          typeof item.route !== 'string' &&
          link.id === 'events' &&
          item.route.name === 'event-info-event' &&
          ((route.name as string) || '').startsWith('event-info-event') &&
          route.params.event === item.route.params?.event
      )
    },
  },
})
