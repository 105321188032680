<template>
  <transition
    enter-active-class="transition-opacity duration-300 ease-linear"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-300 ease-linear"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="menuStore.isMobileMenuVisible"
      class="fixed inset-0 z-40 xl:hidden"
    >
      <div
        class="absolute inset-0 bg-gray-600 opacity-75"
        @click="menuStore.hideMenu"
      />
    </div>
  </transition>
  <transition
    enter-active-class="transition duration-300 ease-in-out transform"
    enter-from-class="-translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition duration-300 ease-in-out transform"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full"
  >
    <div
      v-show="menuStore.isMobileMenuVisible"
      class="fixed bottom-0 top-0 left-0 z-40 flex flex-col w-full pointer-events-none xl:hidden"
    >
      <MenuMobile class="pointer-events-auto" @close="menuStore.hideMenu" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useMenu } from '~/stores/menu'

const menuStore = useMenu()
</script>
