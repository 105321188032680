<template>
  <nav>
    <div class="flex pl-10 gap-1">
      <template v-for="item in menu" :key="item.id">
        <MenuDesktopButton v-if="!item.hidden" :link="item" />
      </template>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useMenu } from '~/stores/menu'

const { menu } = useMenu()
</script>
